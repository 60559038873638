import { FirebaseAloadedService, firebaseConfig } from "@Src/services/FirebaseAloadedService";
import {AloadedService} from "@Src/services/service";
import { Pitch } from "@Src/models/pitch";
import {Instrument} from "@Src/models/instrument";
import {Country} from "@Src/models/country";
import { Region } from "@Src/models/region";
import { Lead } from "@Src/models/lead";

import { AloadedAdvanceService } from "@Src/services/AloadedAdvanceService";
import { QueryParams } from "@Src/types/models";

export const advanceService : AloadedAdvanceService = new AloadedAdvanceService()

export const masterService : AloadedService = new FirebaseAloadedService(firebaseConfig, "master")


export async function submitLabelAdvanceInquiry(labelAdvanceInquiry: any) : Promise<any> {
  return await advanceService.submitLabelAdvanceInquiry(labelAdvanceInquiry)
}

export async function createUserByEmail(email: string): Promise<{}> {
    return await masterService.createUserByEmail(email)
}

export async function submitPitch(pitch : Pitch): Promise<any> {
    return await masterService.submitPitch(pitch)
}

export async function createStripePaymentIntent(data : {}) : Promise<{}> {
    return await masterService.createStripePaymentIntent(data)
}

export async function fetchNodes<T extends Node>(collectionName: string): Promise<Array<T>> {
    return await masterService.getNodes(collectionName)
}

export async function fetchInstruments(): Promise<Array<Instrument>> {
    // @ts-ignore
    return await fetchNodes<Instrument>("instruments")
}

export async function fetchCountries(): Promise<Array<Country>> {
    // @ts-ignore
    return await fetchNodes<Country>("countries")
}

export async function fetchRegions(): Promise<Array<Region>> {
    // @ts-ignore
    return await fetchNodes<Region>("regions")
}

export async function submitMerchInterest(name: string, email: string, queryParams: QueryParams) {
    return await masterService.submitMerchInterest(name, email, queryParams)
}

export async function createLead(lead: Lead): Promise<Array<Region>> {
    // @ts-ignore
    return await masterService.createLead(lead)
}

export async function fetchAdvanceInquiry(advanceInquiryId: string) : Promise<any> {
  return await advanceService.fetchAdvanceInquiry(advanceInquiryId) as any
}

export async function submitAdvanceInquiryRights(advanceEstimateId: string, rightsValues: Record<string, number>) {
  return await advanceService.submitAdvanceInquiryRights(
    advanceEstimateId,
    rightsValues
  )
}

export async function uploadFiles(files: Array<File>, onUpdate: (t: any) => void): Promise<any> {
  // @ts-ignore
  return await masterService.uploadFiles(files, onUpdate)
}

export async function calculateAdvanceInquiryFunding(advanceInquiryId: string, data: { contractLengthInYears: number, interestedInBuyout: boolean}) {
  return await advanceService.calculateAdvanceInquiryFunding(
    advanceInquiryId,
    data
  )
}

export async function submitAdvanceInquiryFunding(advanceEstimateId: string, value: { interestedInFullBuyout?: boolean, contractLengthInYears: number}) {
  return await advanceService.submitAdvanceInquiryFunding(
    advanceEstimateId,
    value
  )
}

export async function submitAdvanceInquiry(advanceInquiry: any) : Promise<any> {
  return await advanceService.submitAdvanceInquiry(advanceInquiry)
}

export async function findArtistsByName(q: string): Promise<any> {
  return await advanceService.findArtistsByName(q)
}

export async function createStripeCheckoutSession(data: any): Promise<any> {
  return await masterService.createStripeCheckoutSession(data)
}

export async function submitAdvanceInquiryForReview(advanceInquiryId : string, data : any) {
  return advanceService.submitAdvanceInquiryForReview(
    advanceInquiryId,
    data
  )
}