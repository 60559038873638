import { AdvanceInquiry } from "@Src/models/advanceInquiry"
import { SubmitAdvanceInquiryResponseBody } from "@Src/types/models"
 
export class AloadedAdvanceService {
  
  async findArtistsByName(q: string) {
    return await fetch(`${process.env.GATSBY_ADVANCE_API_URL}/findArtistsByName?q=${encodeURIComponent(q)}`).then(r => r.json())
  }
  async calculateInquiryFunding(advanceInquiryId: string, value: { contractLengthInYears: number, interestedInBuyout: boolean}) {
    const { contractLengthInYears, interestedInBuyout } = value
    return await this.callCloudFunction(
      'calculateAdvanceInquiryFunding',
      {
        advanceInquiryId,
        contractLengthInYears,
        interestedInBuyout
      }
    )
  }
  
  async callCloudFunction(functionName : string, parameters: Record<string, any>) : Promise<any> {
    try {
      const result = await fetch(
        `${process.env.GATSBY_ADVANCE_API_URL}/${functionName}`,
        {
          method: 'POST',
          body: JSON.stringify({
            ...parameters
          })
        }
      )
      const data = await result.json()
      return data
    } catch (e) {
      throw e
    }
  } 
  async submitLabelAdvanceInquiry(labelAdvanceInquiry: any) : Promise<any> {
    return await this.callCloudFunction(
      "submitLabelAdvanceInquiry",
      {
        ...labelAdvanceInquiry
      }
    )
  }
  async submitAdvanceInquiry(advanceInquiry : AdvanceInquiry) : Promise<SubmitAdvanceInquiryResponseBody>{
    return await this.callCloudFunction(
      "submitAdvanceInquiry",
      {
        ...advanceInquiry
      }
    )
  }

  
  async calculateAdvanceInquiryFunding(advanceInquiryId: string, value: { contractLengthInYears: number, interestedInBuyout: boolean}) {
    const { contractLengthInYears, interestedInBuyout } = value
    return await this.callCloudFunction(
      'calculateAdvanceInquiryFunding',
      {
        advanceInquiryId,
        contractLengthInYears,
        interestedInBuyout
      }
    )
  }

  async submitAdvanceInquiryFunding(advanceInquiryId: string, value: Record<string, any>) : Promise<any> {
    const { contractLengthInYears, interestedInBuyout } = value
    return await this.callCloudFunction(
      'submitAdvanceInquiryFunding',
      {  
        advanceInquiryId,
        contractLengthInYears,
        interestedInBuyout
      }
    )
  }
  
  async submitAdvanceInquiryRights(advanceInquiryId: string, rightsValues: Record<string, number>) : Promise<any> {
    return await this.callCloudFunction(
      'submitAdvanceInquiryRights',
      {
        advanceInquiryId,
        rightsValues
      }
    )
  }

  async fetchAdvanceInquiry(advanceInquiryId: string) {
    return await fetch(`${process.env.GATSBY_ADVANCE_API_URL}/getAdvanceInquiry?id=${advanceInquiryId}`).then(r => r.json())
  }

  async uploadFiles(files: Array<File>, onUpdate : any) : Promise<any> {
    // Create a root reference
    return new Promise((resolve, reject) => {
      const uploadTasks : any = {}
      const finishedUploads : any = {}
      files.forEach((file) => {
        // @ts-ignore
        const storageRef = ref(this.storage, `uploads/${file.name}`);
        // 'file' comes from the Blob or File API
        // @ts-ignore
        const uploadTask = uploadBytesResumable(storageRef, file)
      
        uploadTask.on('state_changed', (snapshot: any) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadTasks[file.name].progress = progress
          uploadTasks[file.name].state = snapshot.state
          onUpdate({
            uploadTasks,
            finishedUploads
          })
          // @ts-ignore
        }, (error) => { uploadTasks[file.name].error = error.code }, () => {
            // @ts-ignore
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              finishedUploads[file.name] = {
                name: file.name,
                path: uploadTask.snapshot.ref.fullPath,
                url: downloadURL
              }
              delete uploadTasks[file.name]
              onUpdate({
                uploadTasks,
                finishedUploads
              })
              if (Object.values(uploadTasks).length < 1) {
                resolve({
                  uploadTasks,
                  finishedUploads
                })
              }
            })
        })
        uploadTasks[file.name] = uploadTask
      })
    })
  }
  
  async submitAdvanceInquiryForReview(advanceInquiryId: string, value: Record<string, any>) : Promise<any> {
    const {
      ableToMoveCatalogue,
      distributorName,
      contractLengthInYears,
      interestedInBuyout,
      files
    } = value
    return await this.callCloudFunction(
      'submitAdvanceInquiryForReview',
      { 
        advanceInquiryId,
        contractLengthInYears,
        distributorName,
        ableToMoveCatalogue,
        isMobile: value.isMobile,
        interestedInBuyout,
        files
      }
    )
  }
}