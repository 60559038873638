import React from 'react';
import cx from 'classnames';

import BaseButton from '../BaseButton';
import * as styles from './index.module.scss';
import { PageThemeType, useApp } from '@Src/context/AppContext';

interface Props {
  isSubmitting?: boolean
  theme: PageThemeType
  label: string | undefined
  isLoading?: boolean
  isSuccess?: boolean
  onClick?: () => void | undefined
  isConfirm?: boolean
  buttonType?: "submit" | "button" | "reset" | undefined,
  isItalic?: boolean
  style?: any
  isUnderlined?: boolean
  yellow?: boolean
}

export function SubmitButton ({
  label,
  isLoading,
  isSubmitting = false,
  isConfirm,
  isSuccess = false,
  yellow = false,
  isItalic = false,
  isUnderlined = false,
  theme,
  onClick,
  style,
  buttonType = 'submit'
}: Props) {
  return (
    <BaseButton
      /* @ts-ignore */
      className={cx(styles.submitButton, (theme === 'night' || theme === 'dark') && styles.submitButtonNight, isSuccess && styles.submitButtonSuccess, isItalic && styles.submitButtonItalic, isUnderlined && styles.submitButtonUnderlined, yellow && styles.submitButtonYellow)} 
      // @ts-ignore
      style={style}
      
      isSubmitting={isSubmitting || isLoading}
      isConfirm={isConfirm}
      buttonType={buttonType}
      theme={theme}
      onClick={onClick}
      link={{
        url: '',
        id: 'submit',
        target: '_modal',
        label,
        primary: true
      }}
    />
  )
}