import React from 'react';

import * as styles from './index.module.scss';

interface Props {
  children: any
}

export const StripedHeading = ({
  children
}: Props) => (
  <h1 className={styles.root} style={{/*`calc(100vw / ${children.length} * 1.7)`*/}}><i style={{fontStyle: 'italic'}}>{children}</i></h1>
)