import React, { useEffect, useRef, useState } from 'react';

interface Props {
  onPass: (passed: boolean) => void
  name?: string | undefined
  scrollY: number
}

export function ScrollAnchor({
  onPass,
  name,
  scrollY
}: Props) {

  const [pass, setPass] = useState(false)
  const [center, setCenter] = useState(0)
  const elm = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    onPass(pass)
  }, [pass])

  const monitorScroll = () => {
    
    if (!elm.current && !(elm.current?.parentNode)) {
      return
    }
    let rect = elm.current.getBoundingClientRect()

    let center = rect.top + (rect.height / 2)
    setCenter(center)

    if (center < (window.innerHeight / 2)) { 
        setPass(true)
    } else { 
        setPass(false)
    }
  }

  useEffect(monitorScroll, [scrollY])

  useEffect(monitorScroll, [])
  return (
    <a 
      name={name}
      ref={elm}
      style={{width: '100pt', color: 'red', height: '1px'}}
    >{/*center} {window.innerHeight*/}</a>
  )
}