import React, { ReactElement, useState, useEffect, LegacyRef } from 'react';
import cx from 'classnames';
import * as styles from './index.module.scss';

export interface Props {
	/**
	 * ForwardRef
	 */
	forwardRef?: LegacyRef<HTMLInputElement | HTMLTextAreaElement> | undefined;
	/**
	/**
	 * Input id
	 */
	id?: string;
	/**
	 * Value (current value in input)
	 */
	value?: string;
	/**
	 * Label
	 */
	label?: string;
	/**
	 * Set type for input
	 */
	inputType?: string;
	/**
	 * Callback for change
	 */
	onUpdate?: (value: string) => void;
	/**
	 * Classname to get merged
	 */
	className?: string;

	theme?: string;
	button?: Function | undefined;
	pure?: boolean;
	disabled?: boolean;
	required?: boolean;
	placeholder?: string | undefined;
	style?: any
}

export default function Input({
	id,
	forwardRef,
	label,
	required = false,
	button,
	onUpdate,
	className,
	disabled = false,
	value = '',
	inputType = 'text',
	pure = false,
	placeholder = '',
	theme,
	style,
	...props
}: Props): ReactElement {
	const [currentValue, setCurrentValue] = useState(value);
	const [isPlaceholder, setIsPlaceholder] = useState(true);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	useEffect(() => {
		setIsPlaceholder(currentValue === '');
		if (onUpdate) onUpdate(currentValue);
	}, [currentValue]);
	let classNames = [styles.element]
	let labelClassNames = [styles.label]
	if (theme === 'dark' || theme === 'night') {
		classNames.push(styles.elementNight)
		labelClassNames.push(styles.labelNight)
	}
	return (
		<div className={cx(styles.root, pure && styles.pureRoot, isPlaceholder && styles.placeholder, className)}>
			{label && (
				<label className={cx(labelClassNames)} htmlFor={id}>
					{label}
				</label>
			)}
			{inputType === "textarea" ?
				<textarea
					// @ts-ignore
					ref={forwardRef}
					id={id}
					required={required}
					name={id}
					placeholder={placeholder}
					disabled={disabled}
					className={cx(classNames)}
					defaultValue={currentValue}
					onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => setCurrentValue(event.target.value)}
					onClick={(): void => setIsPlaceholder(false)}
					onFocus={(): void => setIsPlaceholder(false)}
					onBlur={(): void => setIsPlaceholder(currentValue === '')}
					style={style}
					{...props}
				></textarea>
				:
				<input
					// @ts-ignore
					ref={forwardRef}
					id={id}
					name={id}
					disabled={disabled}
					required={required}
					className={cx(classNames)}
					placeholder={placeholder}
					type={inputType}
					value={currentValue}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setCurrentValue(event.target.value)}
					onClick={(): void => setIsPlaceholder(false)}
					onFocus={(): void => setIsPlaceholder(false)}
					onBlur={(): void => setIsPlaceholder(currentValue === '')}
					{...props}
				/>}
			{button ?? null}
		</div>
	);
}
