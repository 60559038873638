import React, { useEffect, useRef, useState } from 'react';
import { start } from 'repl';

import cx from 'classnames';

import * as styles from './index.module.scss';

interface Props {
  scrollY: number;
  children: any;
  visibleClassName?: string;
  hiddenClassName?: string;
  direction?: string;
  style?: any
}

export const ParallaxItem = ({
  scrollY,
  children,
  visibleClassName,
  hiddenClassName,
  direction = '',
  style,
  ...props
}: Props) => {
  const [position, setPosition] = useState(0);
  const [relativeTop, setRelativeTop] = useState(0)
  const elm = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!elm.current && !(elm.current?.parentNode)) {
      return
    }
    let rect = elm.current.parentNode.getBoundingClientRect()

    let center = rect.top + (rect.height / 2)
    let pos = 1
    const endSpaceHeight = window.innerHeight - (window.innerHeight / 2);
    const startSpaceHeight = (window.innerHeight / 4)  ;
    const offsetTop = elm.current.offsetTop
    setRelativeTop(scrollY - offsetTop - (window.innerHeight / 2))
    if (center < startSpaceHeight) {
      // console.log("At the start")
      pos =  (center / startSpaceHeight) 
      
      if (pos < 0) {
        pos = 0
      }
      //console.log(pos) 
    } else if (center > endSpaceHeight) {
      //console.log("At the end")
      pos = 1 - ((center - endSpaceHeight) / endSpaceHeight) 
      if (pos > 1) {
        pos = 1
      }
      //console.log(pos) 
    } else {
      //console.log("At the middle")
      //console.log(pos)
    }
    
    setPosition(
      pos
    )
  }, [scrollY])
  return (
    <div 
      ref={elm}
      className={cx(position === 1 ? visibleClassName : hiddenClassName, styles.parallaxItem, styles['parallaxItem_' + direction], styles[' ' + (position === 1 ? 'visible ': 'hidden')])} style={{'--position': position, ...(style ?? {})}}>
      {children instanceof Function ? children({ position, relativeTop }) : children}
    </div>
  )
}