import Heading from '@Src/components/ui/Heading';
import React, { useContext, useEffect, useState } from 'react';
import Text from '@Src/components/Text';
import * as styles from './index.module.scss';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import ContentLayout, { ContentLayoutContext } from '@Src/components/sitestructure/ContentLayout';
import Input from '@Src/components/ui/Input';
import { submitMerchInterest } from '@Src/actions/aloaded';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import { StripedHeading } from '@Src/components/ui/StripedHeading';
import { ParallaxItem } from '@Src/components/ParallaxItem';
import { ScrollAnchor } from '@Src/components/ui/ScrollAnchor';
import Link from '@Src/components/ui/Link';
import { SnappyContainerRow } from '@Src/components/ui/SnappyContainer';
import { ParallaxVideo } from '@Src/components/ui/ParallaxVideo';
import { HoverImage } from '@Src/components/ui/HoverImage';
import { CenterContainer } from '@Src/components/ui/CenterContainer';
import { createPortal } from 'react-dom';
import { QueryParams } from '@Src/types/models';

const portalRoot = typeof document !== `undefined` ? document.body : null

export function MerchPage({
  transitionStatus
}) {
	const { theme, setTheme: setPageTheme} = usePageTheme();
	const { mode, setPageType, setPageTitle } = useApp();
  const [name, setName] = useState('First Last');
  const [email, setEmail] = useState('');
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isError, setError] = useState(false)
  const [showBottomCTA, setShowBottomCTA] = useState(false)
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!event.target.checkValidity()) {
      return
    }
    setSubmitting(true)
    submitMerchInterest(
      name,
      email,
      queryParams
    ).then(result => {
      // @ts-ignore
      if (window?.dataLayer instanceof Array) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'submit_merch_interest',
          type: 'event',
          eventCallback: () => {
            setSubmitting(false)
            setSubmitted(true)
          },
          eventTimeout: 2000
        })
      } else {
        setSubmitting(false)
        setSubmitted(true)
      }
      // window.location.href = `/merchondemand/submitted`
    }).catch(e => {
      console.error(e)
      setSubmitted(false)
      setError(true)
    })
    return false;
  }
	useEffect(() => {
		setPageType('signup');
    let query = new URLSearchParams(window.location.search);
    const newQueryParams = {
      utm_campaign: query.get('utm_campaign'),
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_content: query.get('utm_content'),
      fbclid: query.get('fbclid'),
      referrer: document.referrer
    }
    setQueryParams(newQueryParams)
	}, []);
  useEffect(() => {
    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme('dark');
      setPageType('signup');
    }
  }, [transitionStatus]);

  useEffect(() => {
      if (transitionStatus == 'entered') {
        setPageTheme('dark');
      }
  }, [mode]);

  const handlePass = (passed: boolean) => { 
    setPageTheme(passed ? 'light' : 'dark')
    setShowBottomCTA(passed)
    console.log("passed", passed)
  }
 
  return (
    <ContentLayout fullWidth={true} transitionState={transitionStatus} type="signup">
      <ContentLayoutContext.Consumer>
        {({scrollY}) => (
          <>
            <div style={{ padding: 0, marginTop: '-0pt'}}>
              <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', justifyContent: 'center'}}>
                <a name="signup" />
                <StripedHeading>Artist Merch, Delivered</StripedHeading>
                <div style={{marginTop: 30, marginBottom: 30}}>
                  <Text theme={theme} body={`Free High-Quality Merch on Your Spotify Profile`} />
                </div>
                {isSubmitted ?
                  <Text theme={theme} htmlText={`Access requested successfully! Thanks!`} />
                :
                  <form style={{opacity: isSubmitted ? 0.5 : 1, margin: 10}} method="POST" onSubmit={handleSubmit}>
                   <fieldset style={{display: 'grid', gridTemplateColumns: '2fr 1fr'}}>
                      <div style={{border: ' 1px solid white', background: 'white', paddingTop: 10, paddingLeft: 10, margin: 0}}>
                        <Input
                          inputType="email"
                          required={true}
                          theme={"light"}
                          label="Your e-mail"
                          pure={true}
                          onUpdate={(value) => setEmail(value)}
                          style={{padding: 0}}
                        />
                      </div>
                      <SubmitButton
                        buttonType="submit"
                        onClick={() => {}}
                        theme={"light"}
                        yellow={true}
                        isLoading={false}
                        isConfirm={false}
                        isSubmitting={isSubmitting}
                        isItalic={true}
                        isUnderlined={true}
                        style={{fontStyle: 'italic', padding: 0, paddingLeft: '15pt', paddingRight: '15pt', margin: 0}}
                        label={isSubmitted ? 'Sucessfully submitted' : isSubmitting ? 'Requesting access' : 'Request access'}
                      />
                      </fieldset>
                  </form>
                }
                <img src="/images/creator toolet.png" style={{marginTop: 30, marginBottom: 30, width: 'calc(100% - 0pt)'}} />
              </div>
              <br />
              <ScrollAnchor scrollY={scrollY} onPass={handlePass} />
              <div className={styles.root}>
                 
                <div className={styles.grid2}>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={1600}>
                    <div>
                      <Text
                        theme={theme}
                        heading03={`Merch with zero upfront costs - shipping worldwide`} />
                    </div>
                    <div>
                      <img src="/images/spotify-pinned-merch.png" style={{width: '100%'}} />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={1600}>
                    <div style={{padding: 10, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', alignItems: 'center'}}>
                      <Text theme={theme} heading03={`It's too easy`} />
                      <Text theme={theme} htmlText={`Create and customize your merch designs in minutes with our easy-to-use design tool. Choose from a wide range of styles, colors, and sizes to suit your fans' preferences.`} />
                    </div>
                    <div>
                      <img src="/images/spotify-pin-merch-desktop.png" style={{width: '100%'}} />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={1600}>
                    <div>
                      <Text
                        theme={theme}
                        heading03={`Pick textile. Add art`} />
                      <Text
                        theme={theme}
                        heading03={`Set price, Connect Spotify`}
                      />
                      <Text
                        theme={theme}
                        htmlText={`Keep 80% of profits after production. €40 hoodie to a fan? That's 8€ straight to you. That €1000 hoodie fan sale? €584 straight to you. Boost your earnings effortlessly with our merch on demand service`} />
                    </div>
                    <div>
                      <img src="/images/aloaded tee.png" style={{width: '100%'}} />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={1600}>
                    <div style={{flexDirection: 'column'}}>
                      <Text theme={theme} heading03={`Fast & Easy Design Process`} />
                      <Text theme={theme} htmlText={`Create and customize your merch designs in minutes with our easy-to-use design tool. Choose from a wide range of styles, colors, and sizes to suit your fans' preferences.`} />
                    </div>
                    <ParallaxItem style={{width: '100%'}} scrollY={scrollY}>
                      <iframe
                        style={{border: 0, width: '100%', height: 'auto', aspectRatio: '16/9'}}
                        src="https://www.youtube.com/embed/6MNXxTd0dck"
                        title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </ParallaxItem>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={1600}>
                    <div>
                      <Text theme={theme} heading03={`Money Makin' Merch Magic`} />
                      <Text theme={theme} htmlText={`Sell merch right where fans come to support you. Use the artist pick feature to lift your sales even higher (on your Spotify profile)`} />
                    </div>
                    <div>
                      <img  
                        src="/images/spotify-pin-merch-desktop.png" 
                        style={{width: '100%'}} 
                      /> 
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={300}>
                    <div>
                      <Text heading03={`It Was All a Stream`} theme={theme} />
                      <Text theme={theme} htmlText={`It used to be just streams. With recent changes to Spotify, now it's direct-to-fan merch sales, appearing higher on your artist profile, making it even more accessible. Our integration ensures your designs are connected to your profile, increasing visibility and boosting your earnings.`} />
                    </div>
                    <div>
                      <img src="/images/in mourning-mockuuups-child-working-on-computer-mockup.jpeg" style={{width: '100%'}} />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={300}>
                    <div>
                      <Text heading03={`No Inventory, No Hassles`} theme={theme} />
                      <Text theme={theme} htmlText={`We handle the printing and shipping directly to your fans, eliminating the need for inventory management or running to the post office. Focus on your music while we take care of the rest.`} />
                    </div>
                    <div>
                      <img src="/images/westkust-mockuuups-macbook-pro-mockup-on-a-table-with-a-plant-in-the-background.jpeg" style={{width: '100%'}} />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={300}>
                    <div>
                      <Text
                        theme={theme}
                        heading03={`Go Crazy! But Black and white seem to be the most popular colors`}
                      />
                    </div>
                    <div>
                      <img
                        src="/images/gif-square-product-scroll.gif"
                        style={{width: '100%'}}
                      />
                    </div>
                  </div>
                  <div className="SnappyContainerRow" theme={theme} scrollY={scrollY} snapHeight={300}>
                    <div>
                      <Text theme={theme} heading03={`See who's live with us already`} /> <br />
                      <Link 
                        url="https://shop.aloaded.com"
                      >
                        <Text theme={theme} htmlText={`shop.aloaded.com`} />
                      </Link>
                    </div>
                    <div>
                      <img
                      style={{width: '100%'}}
                        src={'/images/aloaded pack laying down.jpg'}
                      />
                    </div>
                  </div>
                  <CenterContainer style={{aspectRatio: '1/1', gap: '50pt', textAlign: 'center'}}>
                    <img src="/images/hand-mockup.png" style={{width: '50%'}} />
                    <Text heading01={`Launch Your Custom Merch Store Now!`} theme={theme} />
                  </CenterContainer>
                  {portalRoot && createPortal( 
                    <>
                      <div className={cx(styles.ctaRow, showBottomCTA && styles.ctaRowVisible)}>
                        <SubmitButton
                          style={{display: 'unset', width: 'auto', border: '1px solid black', boxShadow: '0 10pt 13pt rgba(225, 255, 0, .5)', fontStyle: 'italic', margin: 0}}
                          yellow={true}
                          theme={'light'}
                          isItalic={true}
                          isUnderlined={true}
                          //theme={mode === 'light' ? 'dark' : 'light'} 
                          onClick={() => {window.location.href = '#signup'}} 
                          label={`Get Started with Merch On Demand Today`}
                        />
                      </div> 
                    </>,
                    portalRoot
                  )}
                  {/*<div className={styles.ctaRow}>
                    <SubmitButton
                      style={{display: 'unset'}}
                      theme={"light"}
                      yellow={true}
                      onClick={() => {window.location.href = '#signup'}} 
                      label={`Get Started with Merch On Demand Today`} />
                        </div>*/}
                </div> 
              </div>
            </div>
            <div className={styles.footer} style={{padding: '20pt', fontSize: 18, flexDirection: 'column', display: 'flex', gap: 10}}> 
              <Text theme={theme} body={`Merch on demand is a new trend in the world of fashion and apparel. It allows customers to create custom clothing and accessories with their own designs or logos. This type of service is gaining popularity because it allows individuals to express their unique style and personality through their clothing`} />
              <Text theme={theme} body={`With merch on demand, customers can choose from a variety of products, including t-shirts, hats, bags, and more. They can then use online design tools to create their own designs or upload their own logos and images. The finished product is then printed and shipped directly to the customer. One of the key benefits of merch on demand is that it allows for small-scale production. This means that customers can order just a few items at a time, rather than having to purchase large quantities of a single design. This is perfect for individuals or small businesses who want to create personalized items without having to invest in a large inventory`} />
              <Text theme={theme} body={`Another benefit of merch on demand is that it allows for quick turnaround times. Customers can design and order their items in just a few minutes, and the finished products are usually shipped within a few days. This is a major advantage over traditional clothing production, which can take weeks or even months to complete`} />
              <Text theme={theme} body={`Overall, merch on demand is a convenient and cost-effective way for individuals and small businesses to create custom clothing and accessories. With a wide range of products and easy-to-use design tools, it has never been easier to express your unique style and personality through your clothing.`} />
            </div>
            <div className={styles.footer} style={{marginBottom: 100, padding: '20pt', fontSize: 18, flexDirection: 'column', display: 'flex', gap: 10}}>
              <Text theme={theme}>Company details:</Text>
              <Text theme={theme}>ALOADED AB</Text>
              <Text theme={theme}>Bondegatan 21</Text>
              <Text theme={theme}>116 33 Stockholm</Text>
              <Text theme={theme}>Sweden</Text>
              <Text theme={theme}>Company Approved for Company Tax (Godkänd för F-skatt) with national company registration number: 559124-3836 VAT SE559124383601</Text>
            </div> 
          </>
        )}
      </ContentLayoutContext.Consumer>
    </ContentLayout>
  )
}

export default MerchPage;
