import React from 'react';

import * as styles from './index.module.scss'

interface Props {
  children: any
}

export function CenterContainer({
  children,
  ...props
} : Props) {
  return (
    <div className={styles.centerContainer} {...props}>{children}</div>
  )
}